import { IProposalRecord } from "../models/proposal.model";
import ResourceFactory from "./resource";

const resourceKey = "proposals";
const resourceUrl = "proposals";
const resourceIdKey = "proposalID";

const { reducer, fetchList, create, fetch } = ResourceFactory<IProposalRecord>(
  resourceKey,
  resourceUrl,
  resourceIdKey
);

export type ProposalResourcesState = ReturnType<typeof reducer>;
export const fetchProposalsList = fetchList;
export const createProposal = create;
export const fetchProposal = fetch;
export default reducer;
