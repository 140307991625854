import React from "react";
import clsx from 'clsx';
import { connect } from "react-redux";
import { makeStyles, withStyles, createMuiTheme } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepConnector from '@material-ui/core/StepConnector';
import { withRouter, RouteComponentProps } from "react-router-dom";
import StepLabel from "@material-ui/core/StepLabel";
import { IRootState } from "../../store/reducers";

import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import "./ProposalStepper.scss";

const styles = theme => ({
  root: {
    width: "90%",
    margin: "0 auto",
    marginBottom: "2.5rem"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: "inline-block"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  active: {
    color: "#d85d0f !important"
  },
  styles: {
    fontSize: "2.2rem !important",
    fontWeight: 900
  },
  icon: {
    fontSize: "2.4rem"
  }
});

export interface IStep {
  label?: string;
  to?: string;
  completed?: boolean;
  optional?: boolean;
}

export interface IStepperProps extends RouteComponentProps {
  steps: IStep[];
  activeStep: number;
  classes: any;
  onClick: (e: any, stepIndex: number) => any;
}

export interface IStepperState {
  activeStep: number;
  skipped: any;
  completed: any;
}

const ColorlibConnector = withStyles({
  line: {
    height: 0,
    border: 0,
    borderRadius: 0,
  },
})(StepConnector);

export class ProposalStepper extends React.Component<IStepperProps, IStepperState> {
  render() {
    const { steps, classes, history, onClick, activeStep } = this.props;
    const icons = {
      1: <GroupAddIcon />,
      2: <SettingsIcon />,
    };

    function getStepActiveState(activeStep, index) {
      return activeStep == index ? "stepper__step__active" : "stepper__step";
    }

    return (
      <div className="stepper">
        <div className={classes.root}>
          <Stepper alternativeLabel nonLinear activeStep={activeStep} style={{ backgroundColor: "transparent" }} connector={<ColorlibConnector />}>
            {steps.map((step, index) => (
              <Step className="stepper__step" key={step.label}>
                {/* <StepButton onClick={e => onClick(e, index)} completed={step.completed}>
                  <StepLabel
                    StepIconComponent={() => (
                    <div className={getStepActiveState(activeStep, index)}>
                    </div>)}
                    StepIconProps={{
                      classes: {
                        root: classes.icon1,
                        active: classes.active1,
                        text: classes.styles1
                      }
                    }}>
                    <span className={getStepActiveState(activeStep, index)}>{step.label}</span>
                  </StepLabel>
                </StepButton> */}
              </Step>

            ))}
          </Stepper>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(ProposalStepper));
