import { isEmptyOrDefault } from "./utils.defaultValues";
import { NumberFormatValues } from "react-number-format";

export const PHONE_NUMBER_REGEX = new RegExp(/(^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}(?:[\s.-]\d{1,6})?$)/);
export const ZIP_CODE_REGEX = new RegExp(/^[0-9]*$/);
export const EXTERNAL_ID_REGEX = new RegExp(/^\d{1,9}$/);

/**
 * Check if the input string is undefined, null, or white space from beginning to end of string.
 * @param value
 */
export const isEmpty = (value: string): boolean => {
  return value === null || value === undefined || value.match(/^\s*$/) !== null;
};

/**
 * Ensure the specified number value is a valid number between 0-100.
 * Allow for undefined.
 * @param {NumberFormatValues} values provided by a NumberFormatField
 */
export const isValidPercentage = (values: NumberFormatValues): boolean => {
  if (!values.floatValue) {
    return true;
  }
  return values.floatValue <= 100;
};
