import React from "react";
import { BASE_API_URL } from "../../constants/EnvConstants";
import ReactDOM, { render } from "react-dom";
import RouteContext, { Redirect } from "react-router";
import { get, set } from "lodash";
import { connect } from "react-redux";
import { Form as FinalForm, FormRenderProps } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { IRootState } from "../../store/reducers";
import ProposalStepper from "../../components/ProposalStepper/ProposalStepper";
import Toast from "../../components/Toast/Toast";
import { defaultOptionValue, undefinedIfDefault } from "../../util/utils.defaultValues";
import { fetchBrokerProfile } from "../../store/reducers/authentication";
import {
  fetchProposalOptions,
  fetchStatusList,
  fetchProposal,
  fetchProposalClassifications,
  saveProposal,
  resetBuilder,
  hideToast,
  hideSaveToast,
  fetchOccupation,
  saveProposalNext,
  saveProposalPrevious,
  submitProposal,
  changeStatusTypeForProposal
} from "../../store/reducers/proposalOptions";

import {
  fetchClientOptions,
  fetchCaseDesignOptions,
  fetchProductTypeOptions
} from "../../store/reducers/clientOptions";

import * as S from "../../constants/StringConstants";
import { RouteComponentProps } from "react-router";
import Client from './Client';
import CaseDesign from './CaseDesign';
import Welcome from './Welcome';
import { Container, Row, Col } from 'reactstrap'
import "./ClientBuilder.scss";
import PreFooter from '../Layout/PreFooter';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Chevrons from '../../components/Chevrons/Chevron';
import Ovals from '../../components/Chevrons/Oval';

export const saveSymbol = Symbol("SAVE FORM");
export const stepSymbol = Symbol("STEP FORM");
export const stepIndexSymbol = Symbol("STEP INDEX");

const numPages = 2;

/**
 * Client Builder Component Properties
 */
export interface IClientBuilderProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string, client: string }> {
      brokerID: string;
      clientID: string;
      onBrokerChange: (notes: string) => void;
    }

/**
 * Internal state of the Client Builder
 */
interface IClientBuilderState {
  // current page number
  page: number;
  isSubmitting: boolean;
}

/**
 * Client Builder Component
 *
 * Contains 2 pages and handles validation and submission across pages.
 */
export class ClientBuilder extends React.Component<IClientBuilderProps, IClientBuilderState> {
  constructor(props) {
    super(props);
    
    const page = get(props, "history.location.state.pageIndex", 0);

    if (page !== 0) {
      this.props.history.replace(undefined, {});
    }

    this.state = {
      page: page,
      isSubmitting: false
    };
  }

  async componentDidMount() {
    const { brokerID } = this.props;

    const id = parseInt(brokerID, 10);
    if (id) {
      await this.props.fetchBrokerProfile(`${id}`);
      this.props.fetchClientOptions();
      this.props.fetchCaseDesignOptions();
      this.props.fetchProductTypeOptions();
    }
  }

  /**
   * Save the current values and navigate to the next page.
   */
  next = async values => {
    const { page } = this.state;
    await this.storeClient(Math.min(page + 1, numPages - 1), values, this.props.saveProposalNext);
  };

  /**
   * Submit the current values and navigate to the next page.
   */
  submit = async values => {
    const { currentProposal } = this.props;
    const campaignId = get(this.props.match.params, "campaignId");
    
    var caseDesign = values;
    var newProposal = {
      ...currentProposal,
      caseDesign,
      campaignId
    }
    
    await this.props.submitProposal(newProposal);
  };

  /**
   * Submit the current values and navigate to the previous page.
   */
  previous = async values => {
    const { page } = this.state;
    await this.storeCaseDesign(Math.max(page - 1, 0), values, this.props.saveProposalPrevious);
  };

  storeCaseDesign = async (nextPage: number, values: any, save: (values: any) => void) => {
    const caseDesign = this.collectCaseDesignFormValuesToProposal(values);

    const { currentProposal } = this.props;
    var newProposal = {
      ...currentProposal,
      caseDesign
    };
    const result = await save(newProposal);
    
    this.setState(state => ({
      page: nextPage
    }));
  };

  storeClient = async (nextPage: number, values: any, save: (values: any) => void) => {
    const { brokerID } = this.props;

    const client = this.collectClientFormValuesToProposal(values);
    const { currentProposal } = this.props;
    var newProposal = {
      ...currentProposal,
      proposalID: "new", // I want to rename that in future
      brokerID: brokerID,
      step: this.state.page,
      client
    };

    const result = await save(newProposal);
    
    this.setState(state => ({
      page: nextPage
    }));
  };

  collectClientFormValuesToProposal = formValues => {
    const client: {
      credentialTypeID,
      tobaccoTypeID,
      tobaccoFrequencyID,
      isReplaceExistingCoverage,
      dateOfBirth,
      duties,
      occupationOther,
      firstName,
      lastName,
      genderTypeID,
      occupationTypeID,
      prefixTypeID,
      stateID,
      weight,
      height,
      
      yearsInBusiness,
      percentWorkFromHome,
      govtYears,
      groupCap,
      groupPercentage,

      isBusinessOwner,
      isGovernment,
      governmentTypeID,
      doesUseTobacco,
      doesWorkFromHome,
      hasExistingCoverage,
      hasMedicalNotes,
      hasSpouse,

      annualIncome,
      bonus,
      employees,
      individualCoverage,
      tobaccoLastUsedDate,
      tobaccoFrequencyTypeID,
      paidByTypeID,
      spouseDetails,
      passiveIncome,

      conditions
    } = formValues;

    client.genderTypeID = undefinedIfDefault(client.genderTypeID);
    client.tobaccoTypeID = undefinedIfDefault(client.tobaccoTypeID);
    client.tobaccoFrequencyID= undefinedIfDefault(client.tobaccoFrequencyID);

    return client;
  };

  collectCaseDesignFormValuesToProposal = formValues => {
    return formValues;
  }
  /**
   * Render the component for the active step in the ProposalBuilder.
   * @param formProps
   */
  renderActivePage(proposal: any, clientID: string) {
    switch (this.state.page) {
      case 0:
        return (
          <Client
            next={this.next}
            proposal={proposal}
            clientID={clientID}
          />
        );
      case 1:
        return (
          <CaseDesign
            next={this.submit}
            previous={this.previous}
            proposal={proposal}
          />
        );
        
      default:
        this.setState(state => ({ page: 0 }));
        return null;
    }
  }

  render() {
    const { isSubmitToastVisible, isSaveToastVisible, currentProposal } = this.props;

    const { page } = this.state;
    const { currentBroker, brokerID, clientID } = this.props;

    if (isSubmitToastVisible) {
      const campaignId = get(this.props.match.params, "campaignId");
      var url = '/quotesent/' + brokerID;
      if (campaignId){
        url += "/campaign/" + campaignId;
      }
      if (this.props.history && this.props.history.location && 
         this.props.history.location.pathname && this.props.history.location.pathname.startsWith("/client/"))
      {
        url += "/client"
      }
      
      return <Redirect to={url} />;
    }
    
    var logoAffiliateUrl = undefined;
    var logoBrokerUrl = undefined;
    if (currentBroker && currentBroker.brokerLogo){
      logoBrokerUrl = BASE_API_URL + "brokers/" + currentBroker.brokerLogo + "/brokerLogo";
    }
    if (currentBroker && currentBroker.affiliateLogo){
      logoAffiliateUrl = BASE_API_URL + "affiliates/" + currentBroker.affiliateLogo + "/affiliateLogo";
    }
    
    return (
      <div>
        {clientID ? "" : <Header /> }
        
        <br/><br/>
        <div>
          {clientID ? "" : <Welcome /> }
        
          <Toast
            message={S.PB_SUBMIT_SUCCESS_TOAST}
            onClose={() => {
              this.props.hideToast();
            }}
            open={isSubmitToastVisible}
          />
          <Toast
            message={S.PB_PROPOSAL_SUBMITTED}
            onClose={() => {
              this.props.hideSaveToast();
            }}
            open={this.state.isSubmitting}
          />
          <div className="proposal-details__builder">
            <Container>
              <Row>
                <Col sm="12" className="text-center">
                  <h1 className='heading1 orange'>{S.PDC_BROKER_QUOTE_TITLE}</h1>
                </Col>
              </Row>
              <Row>
                <Col sm="1" className="text-center">
                </Col>
                {/* <Col sm="10" className="text-center">
                  <Ovals />
                </Col> */}
                <Col sm="1" className="text-center">
                </Col>
              </Row>
              {logoBrokerUrl && logoAffiliateUrl ?
              <Row>
                <Col sm="2">&nbsp;</Col>
                <Col className="logoPreviewContaner">
                  <img src={logoBrokerUrl} className="logoPreview" />
                </Col>
                <Col className="logoPreviewContaner">
                  <img src={logoAffiliateUrl} className="logoPreview" />
                </Col>
                <Col sm="2">&nbsp;</Col>
              </Row>
              :
              <Row>
                {logoBrokerUrl ? 
                <Col className="logoPreviewContaner">
                  <img src={logoBrokerUrl} className="logoPreview" />
                </Col>
                : "" }
                {logoAffiliateUrl ? 
                <Col className="logoPreviewContaner">
                  <img src={logoAffiliateUrl} className="logoPreview" />
                </Col>
                : "" }
              </Row>}
            </Container>
            <ProposalStepper
              activeStep={page}
              onClick={async (e, stepIndex) => {
                const form = document.getElementById(`proposal-builder-form-${page}`);
                if (form) {
                  window[stepSymbol] = true;
                  window[stepIndexSymbol] = stepIndex;
                  form.dispatchEvent(new Event("submit", { cancelable: true }));
                }
              }}
              steps={[
                {
                   //label: S.PDS_CLIENT, 
                   to: `/proposals/${brokerID}/details/client`
                },
                {
                  //label: S.PDS_CASE_DESIGN,
                  to: `/proposals/${brokerID}/details/case-design`
                }
              ]}
            />
            {this.renderActivePage(currentProposal, clientID)}
          </div>
        </div>
        {/* <PreFooter /> */}
        {clientID ? "" : <Footer /> }
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  let bpTypeFullList = state.proposalOptions.classificationOptions ? state.proposalOptions.classificationOptions.bpTypeFullList : [];
  let designTypeFullList = state.proposalOptions.classificationOptions ? state.proposalOptions.classificationOptions.designTypeFullList : [];
  
  return ({
    currentBroker: state.auth.currentBroker,
    isSubmitToastVisible: state.proposalOptions.isSubmitToastVisible,
    statusList: state.proposalOptions.statusList,
    bpTypeFullList: bpTypeFullList,
    designTypeFullList: designTypeFullList,
    isSaveToastVisible: state.proposalOptions.isSaveToastVisible,
    proposalMap: state.proposalOptions.proposalMap,
    currentProposal: state.proposalOptions.currentProposal
  });
};

const mapDispatchToProps = {
  fetchProposalOptions,
  fetchStatusList,
  fetchProposal,
  fetchProposalClassifications,
  saveProposal,
  submitProposal,
  resetBuilder,
  hideToast,
  hideSaveToast,
  fetchOccupation,
  saveProposalNext,
  saveProposalPrevious,
  changeStatusTypeForProposal,

  fetchBrokerProfile,
  fetchClientOptions,
  fetchCaseDesignOptions,
  fetchProductTypeOptions
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientBuilder);
