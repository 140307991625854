import "./Footer.scss";
import React from "react";
import { IRootState } from "../../store/reducers";
import { connect } from "react-redux";
import { Container, Row, Col } from 'reactstrap'

export interface IFooterState {
  }
  
  export interface IFooterProps extends StateProps, DispatchProps {
  }
  
  class Footer extends React.Component<IFooterProps, IFooterState> {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoggedIn: false
      };
    }
  
    render() {
      
      return (
        <footer>
            <Container>
              <Row className="first-footer">
                <Col sm="6" className="footer-widget">
                  <div id="text-2" className="fwidget et_pb_widget widget_text">
                      <h4 className="title">Home Office</h4>
                      <div className="textwidget custom-html-tile">
                          <p>4444 Zion Avenue<br />
                          San Diego, CA 92120</p>
                      </div>
                  </div>
                </Col>
                <Col sm="3" className="footer-widget">
                  <div id="custom_html-2" className="widget_text fwidget et_pb_widget widget_custom_html">
                      <div className="textwidget custom-html-widget">
                          <a id="phone-number" href="tel:800-898-9641">800-898-9641</a>
                      </div>
                  </div> 
                </Col>
                <Col sm="3" className="footer-widget">
                  <div id="custom_html-3" className="widget_text fwidget et_pb_widget widget_custom_html">
                      <div className="textwidget custom-html-widget">
                          <a id="email" href="mailto:info@diservices.com">info@diservices.com</a>
                      </div>
                  </div> 
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <div className="footer-bottom">
                    <a href="https://www.diservices.com" target="_blank">
                      <div className="didonerightLeft" />
                    </a>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="footer-last">
                    <p className="footer-info">Copyright © 2020&nbsp;
                        <a href="https://www.diservices.com" target="_blank">Disability Insurance Services, Inc.</a>
                    </p>
                  </div>
                </Col>
              </Row> */}
            </Container>
            <div className="footer-bottom">
              <Container>
                <Row>
                  <Col>
                    <a href="https://www.diservices.com" target="_blank">
                      <div className="didonerightLeft" />
                    </a>
                  </Col>
                </Row>
              </Container>
            </div>
            <Container>
              <Row>
                <Col>
                <div className="footer-last">
                  <p className="footer-info">Copyright © 2020&nbsp;
                      <a href="https://www.diservices.com" target="_blank">Disability Insurance Services, Inc.</a>
                  </p>
                </div>
                </Col>
              </Row>
            </Container>
        </footer>
      );
    }
  }
  
  const mapStateToProps = (state: IRootState) => ({
    
  });
  
  const mapDispatchToProps = {
  };
  
  type StateProps = ReturnType<typeof mapStateToProps>;
  type DispatchProps = typeof mapDispatchToProps;
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Footer);

