/**
 * Age.tsx
 * Disability Insurance Services
 *
 * Created by Jeremy Moyers on Wed
 */

import React from "react";
import { get } from "lodash";
import { Container, Row, Col, Label } from "reactstrap";
import { connect } from "react-redux";

import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import TextField from "../../components/Form/TextField/TextField";
import "./Age.scss"

export interface IAgeProps extends StateProps, DispatchProps {}

class Age extends React.Component<IAgeProps> {
  render() {
    const { age } = this.props;

    return (
      
        <Container className="bootsrap-container-override">
          <Row>
            <Col sm="4">
              <div className="age__text-field-disabled">
                <ul>
                  <li>
                    <span>{S.PDC_YEARS_LABEL}</span>
                  </li>
                  <li>
                    <div className="age__label-wrap">
                      <Label>{age.years}</Label>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm="4">
              <div className="age__text-field-disabled">
                <ul>
                  <li>
                    <span>{S.PDC_MONTHS_LABEL}</span>
                  </li>
                  <li>
                    <div className="age__label-wrap">
                      <Label>{age.months}</Label>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm="4">
              <div className="age__text-field-disabled">
                <ul>
                  <li>
                    <span>{S.PDC_DAYS_LABEL}</span>
                  </li>
                  <li>
                    <div className="age__label-wrap">
                      <Label>{age.days}</Label>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  age: state.proposalOptions.age
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Age);
