import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import AppContainer from "./views/AppContainer";

import QuoteEngine from "./views/QuoteEngine/Quote";
import QuoteWizard from "./views/QuoteEngine/QuoteWizard";
import QuoteSent from "./views/QuoteEngine/QuoteSent";

import Login from "./views/Login/Login";

import PrivateRoute from "./components/PrivateRoute";
import BrokerBuilder from "./views/BrokerBuilder/BrokerBuilder";

const Routes = () => (
  <div className="app-routes">
    <Switch>
      <Route path="/quote/:affiliateId(affiliate/\d+)?/:companyId(company/\d+)?/:campaignId(campaign/\d+)?" component={QuoteEngine} />
      <Route path="/login/(campaign)?/:campaignId?" component={Login} />

      <Route path="/broker/:id/(campaign)?/:campaignId?" component={QuoteWizard} />
      <Route path="/client/:id/(campaign)?/:campaignId?" component={QuoteWizard} />
      <Route path="/brokers/:affiliateId(affiliate/\d+)?/:companyId(company/\d+)?/new/:campaignId(campaign/\d+)?" component={BrokerBuilder} />
      <Route path="/quotesent/:id/(campaign)?/:campaignId?/(client)?" component={QuoteSent} />
      
      <Route
        path="/"
        render={props => (
          <AppContainer>
            <Switch>
              <Redirect to="/quote" />
            </Switch>
          </AppContainer>
        )}
      />
    </Switch>
  </div>
);

export default Routes;
