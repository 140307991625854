import axios from "axios";
import { get, set, startCase } from "lodash";
import { getOr, keyBy } from "lodash/fp";
import { PENDING, FULFILLED, REJECTED } from "./action-type.util";

const getActionSymbol = getOr(null, "meta.resourceSymbol");
const getActionData = getOr(null, "payload.data");

enum SortDirection {
  Asc,
  Desc
}

type SortOption = [string, SortDirection.Asc | SortDirection.Desc];

export class ListOptions {
  page: number = 1;
  rows: number = 25;
  sort: SortOption[] = [];
  query?: { [key: string]: string } = {};
}

interface IResourceMap<R> {
  [id: string]: R;
}

export class ResourceStore<R> {
  loading: boolean = false;
  error: any = {};
  listOptions: ListOptions = new ListOptions();
  total: number = 0;
  projection: Array<number> = [];
  map: IResourceMap<R> = {};
}

export default function ResourceFactory<R>(
  resourceKey: string,
  resourceUrl: string,
  resourceIdKey: string,
  customDefaultState?: () => ResourceStore<R>,
  ...functions: Array<(state: ResourceStore<R>, action: any) => ResourceStore<R>>
) {
  const defaultState = customDefaultState ? customDefaultState() : new ResourceStore<R>();
  const resourceSymbol = Symbol(resourceKey);
  const itemsToMap = keyBy(resourceIdKey);
  const itemsToProjection = items => items.map(i => i[resourceIdKey]);
  const remapItem = item => ({ id: item[resourceIdKey], ...item });
  const remapItems = items => items.map(remapItem);

  const ACTION_TYPES = {
    FETCH_LIST: `${resourceKey}/FETCH_LIST`,
    FETCH: `${resourceKey}/FETCH`,
    CREATE: `${resourceKey}/CREATE`,
    UPDATE: `${resourceKey}/UPDATE`,
    DELETE: `${resourceKey}/DELETE`
  };

  function resourceReducer(state = defaultState, action): ResourceStore<R> {
    if (getActionSymbol(action) !== resourceSymbol) {
      return state;
    }

    switch (action.type) {
      case PENDING(ACTION_TYPES.FETCH):
      case PENDING(ACTION_TYPES.FETCH_LIST): {
        return { ...state, loading: true, error: null };
      }

      case FULFILLED(ACTION_TYPES.FETCH_LIST): {
        const data = getActionData(action);
        const map = itemsToMap(data.items);
        const projection = itemsToProjection(data.items);
        const listOptions = action.meta.listOptions;

        return {
          ...state,
          map: {
            ...state.map,
            ...map
          },
          projection,
          total: data.totalRows,
          listOptions,
          error: null,
          loading: false
        };
      }

      case FULFILLED(ACTION_TYPES.FETCH): {
        const data = getActionData(action);
        const map = itemsToMap([data]);
        return {
          ...state,
          map: {
            ...state.map,
            ...map
          },
          error: null,
          loading: false
        };
      }

      case REJECTED(ACTION_TYPES.FETCH):
      case REJECTED(ACTION_TYPES.FETCH_LIST): {
        return { ...defaultState, error: true }; // TODO: extract error from rejected payload.
      }

      default: {
        return state;
      }
    }
  }

  /**
   * Query a list of resources with the specified options.
   * @param {PageQuery} listOptions - query options.
   */
  function fetchList(listOptions: ListOptions = new ListOptions()) {
    const { page, rows, sort: sortOptions, query } = listOptions;

    const sort = get(sortOptions, "0.0");
    const sortOrder = startCase(get(sortOptions, "0.1"));

    return {
      type: ACTION_TYPES.FETCH_LIST,
      meta: { resourceSymbol, listOptions },
      payload: axios
        .get<R>(resourceUrl, {
          params: {
            ...query,
            page,
            sort,
            sortOrder,
            rows
          }
        })
        .then(response => {
          const items = get(response, "data.items", null);
          if (items) set(response, "data.items", remapItems(items));
          return response;
        })
    };
  }

  /**
   * Fetch a single resource
   * @param {string} id - resource id.
   */
  function fetch(id: string) {
    return {
      type: ACTION_TYPES.FETCH,
      meta: { resourceSymbol },
      payload: axios.get<R>(`${resourceUrl}/${id}`).then(response => {
        const item = get(response, "data", null);
        if (item) set(response, "data", remapItem(item));
        return response;
      })
    };
  }

  /**
   * Create a resource with the specified parameters.
   * @param {[key: string]: any} params - key value pairs to initialize the resource.
   */
  function create(params: { [key: string]: any }) {
    return {
      type: ACTION_TYPES.CREATE,
      meta: { resourceSymbol },
      payload: axios.post(resourceUrl, params)
    };
  }

  /**
   * Update the resource specified by the provided params.
   *
   * **NOTE** - the params must contain the id of the resource to update.
   *
   * @param {[key: string]: any} params - key value pairs to initialize the resource.
   */
  function update(params: { [key: string]: any }) {
    return {
      type: ACTION_TYPES.CREATE,
      meta: { resourceSymbol },
      payload: axios.post(resourceUrl, params)
    };
  }

  /**
   * Delete the resource with the specified id.
   * @param {number} id
   */
  function remove(id: number) {
    return {
      type: ACTION_TYPES.DELETE,
      meta: { resourceSymbol },
      payload: axios.delete(`${resourceUrl}/${id}`)
    };
  }

  return {
    fetchList,
    fetch,
    create,
    update,
    remove,
    reducer: resourceReducer
  };
}
