import React from "react";
import { get } from "lodash";
import { Container, Row, Col, Label } from "reactstrap";
import { connect } from "react-redux";

import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import "./Welcome.scss"
import Toast from "../../components/Toast/Toast";
import {
  hideLoggedInToast
} from "../../store/reducers/authentication";

export interface IWelcomeState {
  isLoggedIn: boolean;
}

export interface IWelcomeProps extends StateProps, DispatchProps {
}

class Welcome extends React.Component<IWelcomeProps, IWelcomeState> {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false
    };
  }

  render() {
    const { currentBroker, isLoggedIn } = this.props;

    var welcomeMessage = "";
    if(currentBroker){
      welcomeMessage = S.WELCOME_BACK_LABEL + currentBroker.firstName + ' ' + currentBroker.lastName;
    }
    return (
      <div>
        <Toast
          message={welcomeMessage}
          onClose={() => {
            this.props.hideLoggedInToast();
          }}
          open={isLoggedIn}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  currentBroker: state.auth.currentBroker,
  isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = {
  hideLoggedInToast
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Welcome);
