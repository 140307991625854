import React from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'

import { Provider } from 'react-redux'
import store from './store/index'

import App from './app'
import setupAxios from './config/axios-config'
import { logout } from './store/reducers/authentication'

import './styles/index.scss'
import './index.scss'

const mountPoint = document.getElementById('root')

const actions = bindActionCreators({ logout }, store.dispatch);
setupAxios(() => actions.logout())

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  mountPoint
)
