import * as React from "react";
import { connect } from "react-redux";
import { Component } from "react";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";

import {
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import * as S from "../../constants/StringConstants";
import { IRootState } from "src/store/reducers";
import "./AppSideNav.scss";

interface IAppSideNavProps extends StateProps, DispatchProps, RouteComponentProps {
}

export interface AppSideNavState {
  dropdownOpen?: boolean;
}

export interface AppSideNavProps {
  dropdownOpen?: boolean;
}

class AppSideNav extends React.Component<IAppSideNavProps, AppSideNavProps, AppSideNavState> {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggle = this.toggle.bind(this);
    this.state = { dropdownOpen: false };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  createProposal = {
    name: S.NEW_PROPOSAL,
    path: "/proposals/new"
  };

  createCompany = {
    name: S.NEW_COMPANY,
    path: "/companies/new"
  };

  createAffiliate = {
    name: S.NEW_AFFILIATE,
    path: "/affiliates/new"
  };

  createBroker = {
    name: S.NEW_BROKER,
    path: "/brokers/new"
  };

  createMap = {
    ["proposals"]: this.createProposal,
    ["companies"]: this.createCompany,
    ["affiliates"]: this.createAffiliate,
    ["brokers"]: this.createBroker
  };

  handleOnClick = () => {
    const path = this.getCreateButton().path;

    if (path === this.createProposal.path) {
      window.open(path);
    } else {
      this.props.history.push(path);
    }
  };

  isActiveClassName = (path: string): string => {
    return this.isActive(path) ? "isActive" : "";
  };

  isActive = (path: string) => {
    return this.props.location.pathname === path;
  };

  getCreateButton() {
    return (
      this.createMap[this.props.location.pathname.split("/").filter(x => x)[0]] ||
      this.createProposal
    );
  }

  getCreateName = () => {
    return this.getCreateButton().name;
  };

  logout = () => {
    //this.props.logout();
  };

  render() {
    return (
      <aside className="side-nav">
        <div className="side-nav__logo" />
        
        <div className="side-nav__links">
         
        </div>
        <div className="side-nav__footer">
          
        </div>
      </aside>
    );
  }
}

const mapStateToProps = ({ auth }: IRootState) => ({
});

const mapDispatchToProps = { };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppSideNav)
);
