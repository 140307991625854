import "./FooterShort.scss";
import React from "react";
import { IRootState } from "../../store/reducers";
import { connect } from "react-redux";
import { Container, Row, Col } from 'reactstrap'

export interface IFooterShortState {
  }
  
  export interface IFooterShortProps extends StateProps, DispatchProps {
  }
  
  class FooterShort extends React.Component<IFooterShortProps, IFooterShortState> {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoggedIn: false
      };
    }
  
    render() {
      
      return (
        <footer id="main-footer">
            <div className="footer-short-bottom">
              <Container>
                <Row>
                  <Col className="footer-short-widget left">
                    <div className="footer-short-info">Copyright © 2020&nbsp;
                        <a href="https://www.diservices.com" target="_blank">Disability Insurance Services, Inc.</a>
                    </div>
                  </Col>
                  <Col  className="footer-short-widget right">
                    <a href="https://www.diservices.com" target="_blank">
                      <div className="didoneright" />
                    </a>
                  </Col>
                </Row>
              </Container>
                
               
            </div>
        </footer>
      );
    }
  }
  
  const mapStateToProps = (state: IRootState) => ({
    
  });
  
  const mapDispatchToProps = {
  };
  
  type StateProps = ReturnType<typeof mapStateToProps>;
  type DispatchProps = typeof mapDispatchToProps;
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(FooterShort);

