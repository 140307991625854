import axios from "axios";
import { IAuthenticationResult } from "./../models/authenticationResult.model";
import { PENDING, FULFILLED, REJECTED } from "./action-type.util";

export const ACTION_TYPES = {
  LOGIN: "auth/LOGIN",
  REFRESH: "auth/REFRESH",
  LOGOUT: "auth/LOGOUT",
  HIDE_LOGGEDIN_TOAST: "auth/HIDE_LOGGEDIN_TOAST",
  FETCH_BROKER_PROFILE: "brokerauth/FETCH_PROFILE"
};

const initialState = {
  sessionInitialized: false,
  isAuthenticated: false,
  currentBroker: undefined,
  isLoggedIn: false
};

export type AuthState = Readonly<typeof initialState>;

/**
 * Authentication Reducer
 */
export default (state: AuthState = initialState, action): AuthState => {
  switch (action.type) {
   
    case FULFILLED(ACTION_TYPES.FETCH_BROKER_PROFILE): {
      const broker = action.payload.data;
      return {
        ...state,
        isLoggedIn: true,
        currentBroker: broker
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_BROKER_PROFILE): {
      return {
        ...state,
        isAuthenticated: false
      };
    }

    // no - network statuses
    case ACTION_TYPES.HIDE_LOGGEDIN_TOAST: {
      return {
        ...state,
        isLoggedIn: false
      };
    }

    default:
      return state;
  }
};

export const hideLoggedInToast = () => {
  return {
    type: ACTION_TYPES.HIDE_LOGGEDIN_TOAST
  };
};

/**
 * Fetch broker profile information.
 */
export const fetchBrokerProfile = (id: string) => ({
  type: ACTION_TYPES.FETCH_BROKER_PROFILE,
  payload: axios.get("brokerspublic/" + id)
});

/*
 * Perform ValidateEmail Network Request.
 */
const validateEmailRequest = (username) => {
  return axios.post("validateEmail", { username })
              .then((data) =>
              {
                var brokerID = getBrokerId(data);
                var result: IAuthenticationResult = {
                  brokerId: brokerID,
                  failed: false,
                  error: undefined
                };

                return result;
              })
              .catch((error) => {
                var result: IAuthenticationResult = {
                  brokerId: undefined,
                  failed: true,
                  error: error.response.data
                };

                return result;
              });
}

/**
 * Initialize a brker session with an email
 * @param {string} username - email of specified broker.
 */
export const validateEmail = (username) => async dispatch => {
  return await validateEmailRequest(username);
};

export const logout = () => dispatch => {
  clearSessionTokens();
  dispatch({ type: ACTION_TYPES.LOGOUT });
};

const getBrokerId = (response: { data?: { id: string; } }) => {
  //localStorage.setItem(K.BROKERID, response.data.externalID); // may be not needed, will remove later
  return response.data.id;
};

/**
 * Clear active users storage.
 */
const clearSessionTokens = () => {
  localStorage.clear();
};
