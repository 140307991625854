import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Prompt } from "react-router-dom";
import { Container, Row, Col, Label, Spinner } from "reactstrap";
import { Form as FinalForm, FormRenderProps } from "react-final-form";
import { get } from "lodash";

import { IRootState } from "../../store/reducers";
import { fetchRiders } from "../../store/reducers/proposalOptions";
import { IProposal } from "../../store/models/proposal.model";
import SingleSelectField from "../../components/Form/SingleSelectField/SingleSelectField";
import CheckboxField from "../../components/Form/CheckboxField/CheckboxField";
import TextField from "../../components/Form/TextField/TextField";
import ToggleField from "../../components/Form/ToggleField/ToggleField";
import NumberField from "../../components/Form/NumberFormatField/NumberFormatField";
import { addDefaultOption, isEmptyOrDefault } from "../../util/utils.defaultValues";
import { OnChange } from "react-final-form-listeners";

import "./CaseDesign.scss";

import * as S from "../../constants/StringConstants";
import { Tooltip } from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import HelpIcon from '@material-ui/icons/Help';

export interface IProposalDetailsCaseDesignProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  proposal: IProposal;
  productTypeID: number;
  next: (values: any) => void;
  previous: (values: any) => void;
  step: (values: any, pageIndex: number) => void;
}

export class ProposalDetailsCaseDesign extends React.Component<IProposalDetailsCaseDesignProps> {
  constructor(props) {
    super(props);
  }

  handleSubmit = values => {
    const { next, previous } = this.props;
    const { formAction, ...rest } = values;

    if (formAction === "submit") {
      next(rest);
    } else {
      previous(rest);
    }
  };

  handleValidate = values => {
    const errors = {};
    
    if(values) {
      if (isEmptyOrDefault(values.productTypeID)) {
        errors["productTypeID"] = S.FORM_FIELD_REQUIRED;
      }
      
      if(!values.isProductTypeKnown) {
        if (isEmptyOrDefault(values.bpTypeID)) {
          errors["bpTypeID"] = S.FORM_FIELD_REQUIRED;
        }
    
        if (isEmptyOrDefault(values.epTypeID)) {
          errors["epTypeID"] = S.FORM_FIELD_REQUIRED;
        }
    
        if (isEmptyOrDefault(values.payorTypeID)) {
          errors["payorTypeID"] = S.FORM_FIELD_REQUIRED;
        }
    
        if (!values.isMaxBenefit && !values.benefitAmount) {
          errors["benefitAmount"] = S.FORM_FIELD_REQUIRED;
        }
      }
    }
    
    return errors;
  };

  render() {
    const {
      caseDesignOptions,
      caseDesignRiderOptions,
      productOptions,
      proposal,
      isSubmitting,
    } = this.props;

    const riders = get(caseDesignRiderOptions, "riders");

    const productOptionsList = addDefaultOption("", productOptions);
    const bpOptions = addDefaultOption("", caseDesignOptions.selectBPList);
    const epOptions = addDefaultOption("", caseDesignOptions.selectEPList);
    const payorOptions = addDefaultOption("", caseDesignOptions.selectPayorList);

    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        validate={this.handleValidate}
        initialValues={proposal.caseDesign}>
        {formProps => {
          const form = formProps.form;
          const isMaxBenefitFieldState = form.getFieldState("isMaxBenefit");
          const isMaxBenefit = get(isMaxBenefitFieldState, "value");

          const isProductTypeKnownFieldState = form.getFieldState("isProductTypeKnown");
          const isProductTypeKnownValue = !get(isProductTypeKnownFieldState, "value");
          return (
            <form
              className="case-design__wrap"
              onSubmit={formProps.handleSubmit}
              id="proposal-builder-form-3">
              <Container>
                <Row>
                  <Col>
                    <h1 className="heading3 case-design__title">
                      {S.PBCD_PRODUCT_SELECTION_TITLE}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                   <SingleSelectField
                      key={"product-type-field"}
                      name={"productTypeID"}
                      label={S.PDO_PRODUCT_TYPE_LABEL}
                      options={productOptionsList}
                      isRequired={true}
                    />
                    <OnChange name='productTypeID'>
                      {value => {
                        if(value && value >= 0){
                          const productTypeID = value;
                          this.props.fetchRiders(productTypeID);
                        }
                      }}
                    </OnChange>
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    <div className="case-design__toggle">
                      <ToggleField
                        key={"is-producttype-known-field"}
                        name={"isProductTypeKnown"}
                        label={"I'm not sure. Let the DIS experts do this for me."}
                      />
                      <Tooltip title={<span className="case-design__tooltipSpan">{S.PDC_I_AM_NOT_SURE_NOTE}</span>} placement="right-start">
                        <HelpIcon className="case-design__tooltipIcon" />
                      </Tooltip>
                      <OnChange name='isProductTypeKnown'>
                        {value => {
                          this.setState({ state: this.state });
                        }}
                      </OnChange>
                    </div>
                  </Col>
                  <Col sm="3" className={!isProductTypeKnownValue ? 'hidden' : ''}>
                    <div className="case-design__toggle">
                      <ToggleField
                        key={"max-benefit-field"}
                        name={"isMaxBenefit"}
                        label={"Max Benefit"}
                      />
                    </div>
                  </Col>
                  <Col sm="4" className={!isProductTypeKnownValue ? 'hidden' : ''}>
                    <SingleSelectField
                      key="dc-payor-field"
                      name={"payorTypeID"}
                      hint={S.PBCD_PAYOR_NOTE}
                      label={S.PBCD_PAYOR_LABEL}
                      options={payorOptions}
                      isRequired={isProductTypeKnownValue}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4" className={!isProductTypeKnownValue ? 'hidden' : 'singleSelectCustom'}>
                    <SingleSelectField
                      key="dc-ep-field"
                      name={"epTypeID"}
                      hint={S.PBCD_EP_NOTE}
                      label={S.PBCD_EP_LABEL}
                      options={epOptions}
                      isRequired={isProductTypeKnownValue}
                    />
                  </Col>
                  <Col sm="4" className={!isProductTypeKnownValue ? 'hidden' : 'singleSelectCustom'}>
                    <SingleSelectField
                      key="dc-bp-field"
                      name={"bpTypeID"}
                      hint={S.PBCD_BP_NOTE}
                      label={S.PBCD_BP_LABEL}
                      options={bpOptions}
                      isRequired={isProductTypeKnownValue}
                    />
                  </Col>
                  <Col sm="4" className={!isProductTypeKnownValue ? 'hidden' : ''}>
                    {!isMaxBenefit && (
                      <NumberField
                        key={"benefit-amount-field"}
                        name={"benefitAmount"}
                        hint={S.PBCD_BENEFIT_NOTE}
                        label={S.PBCD_BENEFIT_AMOUNT}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={"$"}
                        isRequired={isProductTypeKnownValue}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      className=""
                      key={"clientNote"}
                      name={`clientNote`}
                      label={S.PBCD_ADDITIONAL_CLIENT_NOTE}
                    />
                  </Col>
                </Row>
                <Row>
                  {riders.map(r => {
                    return (
                      <Col key={r.key} sm="4" className={!isProductTypeKnownValue ? 'hidden' : 'case-design__riders'}>
                        
                        <CheckboxField label={r.key} name={`riders[]`} value={r.value} />
                        {
                        r.description ?
                        <Tooltip title={<span className="case-design__tooltipSpan">{r.description}</span>} placement="right-start" className="case-design__checkboxHint">
                          <HelpIcon className="case-design__tooltipIcon" />
                        </Tooltip>
                        : ""
                        }
                      </Col>
                    );
                  })}
                </Row>
                <Row>
                  <Col>
                    <div className="spaceOnly">&nbsp;</div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="4" >
                    &nbsp;
                  </Col>
                  <Col sm="2" className="center">
                    <button
                      className="button__orange buttons__display buttons__mr-20"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={e => {
                        formProps.form.change("formAction", "previous");
                      }}>
                      {isSubmitting ? <Spinner color="white" /> : S.PC_PREV_BUTTON}
                    </button>
                  </Col>
                  <Col sm="2" className="center">
                    <div className="overview__next-button buttons__display">
                        <button
                          className="button__orange"
                          type="submit"
                          disabled={isSubmitting}
                          onClick={e => {
                            formProps.form.change("formAction", "submit");
                          }}>
                          {isSubmitting ? <Spinner color="white" /> : S.PC_SUBMIT_BUTTON}
                        </button>
                    </div>
                  </Col>
                  <Col sm="4" >
                    &nbsp;
                  </Col>
                </Row>
              </Container>
              
            </form>
          );
        }}
      </FinalForm>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  productOptions: state.clientOptions.productOptions,
  caseDesignOptions: state.clientOptions.caseDesignOptions,
  caseDesignRiderOptions: state.proposalOptions.caseDesignRiderOptions,
  isSubmitting: state.proposalOptions.isSubmitting,
});

const mapDispatchToProps = {
  fetchRiders
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalDetailsCaseDesign);
