/**
 *  ToggleField.tsx
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Tues May 1 14:46:37 2019
 *  Copyright Seamgen, LLC. All rights reserved.
 */
import React from "react";
import { Field, FieldProps } from "react-final-form";
import classNames from "classnames";

import "./ToggleField.scss";

interface IToggleProps<FieldValue, el extends HTMLInputElement> extends FieldProps<FieldValue, el> {
  label: string;
}

export default class ToggleField<el extends HTMLInputElement> extends React.Component<IToggleProps<number, el>> {
  render = () => {
    const { label, ...fieldProps } = this.props;
    return (
      <Field {...fieldProps} type="checkbox">
        {({ input, meta }) => (
          <div className={classNames("toggle-field", { active: input.value })}>
            <label>
              <input id={input.name} className={""} type="checkbox" {...input} />
              {label}
            </label>
          </div>
        )}
      </Field>
    );
  };
}
