import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Switch, Route, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import cx from "classnames";

import { IRootState } from "../store/reducers";
import AppSideNav from "./AppSideNav/AppSideNav";
import "./AppContainer.scss";

export interface IAppContainerProps extends StateProps, DispatchProps, RouteComponentProps {}

export class AppContainer extends React.Component<IAppContainerProps> {
  render() {
    return (
      <section className="app-container">
        <AppSideNav />

        <main className="main-container">{this.props.children}</main>
      </section>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContainer);
