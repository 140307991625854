import React from "react";
import { Field, FieldProps } from "react-final-form";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import "./NumberFormatField.scss";
import { Tooltip } from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import HelpIcon from '@material-ui/icons/Help';

/**
 * label - label decoration for describing the text input content. ex: "username" or "email";
 * light - assign true to render light themed text field component.
 */
export interface INumberFormatProps<El extends HTMLElement> extends FieldProps<number, El> {
  label?: string;
  light?: boolean;
  thousandSeparator?: boolean;
  prefix?: string;
  decimalScale?: number;
  isAllowed?: (values: NumberFormatValues) => boolean;
  isRequired?: Boolean;
  hint?: String;
}

export default class NumberFormatField<El extends HTMLElement> extends React.Component<
  INumberFormatProps<El>
> {
  render() {
    const {
      label,
      light,
      disabled,
      thousandSeparator,
      prefix,
      decimalScale,
      isAllowed,
      isRequired,
      hint,
      ...fieldProps
    } = this.props;

    return (
      <Field {...fieldProps} component="input">
        {({ input, meta } : {input:any, meta: any}) => {
          const { onChange, ...rest } = input;
          return (
            <div
              className={
                disabled
                  ? "number-field-disabled"
                  : meta.touched && meta.error
                  ? "number-field-error"
                  : light
                  ? "number-field-light"
                  : "number-field-dark"
              }>
              <ul>
                <li>
                  <label>{label}</label>
                  {isRequired ?
                    <label className="required">&nbsp;*</label>
                    : ''
                  }
                  {hint ?
                    <Tooltip title={<span className="single-select-field__tooltipSpan">{hint}</span>} placement="right-start">
                      <HelpIcon className="single-select-field__tooltipIcon" />
                    </Tooltip>
                    : ''
                  }
                </li>
                <li>
                  <NumberFormat
                    className="field-input"
                    thousandSeparator={thousandSeparator}
                    decimalScale={decimalScale}
                    fixedDecimalScale={true}
                    prefix={prefix}
                    disabled={disabled}
                    isAllowed={isAllowed}
                    onValueChange={values => {
                      const { value } = values;
                      const number = parseFloat(value);
                      onChange(number as any);
                    }}
                    {...rest}
                    type="text"
                  />
                  {meta.touched && meta.error && <span>{meta.error}</span>}
                </li>
              </ul>
            </div>
          );
        }}
      </Field>
    );
  }
}
