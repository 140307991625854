import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Spinner } from "reactstrap";
import { Form as FinalForm, FormRenderProps } from "react-final-form";
import { get, set } from "lodash";
import { OnBlur, OnChange } from "react-final-form-listeners";
import axios from "axios";
import arrayMutators from "final-form-arrays";
import { RouteComponentProps } from "react-router";
import formatString from 'format-string-by-pattern'

import * as S from "../../constants/StringConstants";
import * as P from "../../util/utils.validators";
import { IRootState } from "../../store/reducers";
import Age from "./Age";
import TextField from "../../components/Form/TextField/TextField";
import NumberFormatField from "../../components/Form/NumberFormatField/NumberFormatField";
import CheckboxField from "../../components/Form/CheckboxField/CheckboxField";
import ToggleField from "../../components/Form/ToggleField/ToggleField";
import SingleSelectField from "../../components/Form/SingleSelectField/SingleSelectField";
import SimpleDatePickerField from "../../components/Form/SimpleDatePickerField/SimpleDatePickerField";
import AsyncTypeaheadField, { typeAheadErrorSymbol } from "../../components/Form/TypeaheadField/AsyncTypeaheadField";
import { keyValueToDropdownOption, ITypeaheadOption } from "../../components/Form/TypeaheadField/TypeaheadField";
import MedicalNotes from "./MedicalNotes";
import { fetchAge, fetchOccupation } from "../../store/reducers/proposalOptions";
import { IProposal } from "../../store/models/proposal.model";

import "./Client.scss";
import { addDefaultOption, isEmptyOrDefault } from "../../util/utils.defaultValues";

export const saveSymbol = Symbol("SAVE FORM");
export const stepSymbol = Symbol("STEP FORM");
export const stepIndexSymbol = Symbol("STEP INDEX");

export interface IProposalDetailsClientProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> 
{
    proposal: IProposal;
    next: (values: any) => void;
    step: (values: any, pageIndex: number) => void;
    previous: (values: any) => void;
    clientID: string;
}

class Client extends React.Component<IProposalDetailsClientProps> {
  searchOccupations = async (searchText?: string) => {
    const response = await axios.get(
      `proposals/occupations${searchText ? `?searchText=${encodeURIComponent(searchText)}` : ""}`
    );

    return response.data.map(keyValueToDropdownOption);
  };

  handleSubmit = values => {
    const { next, previous } = this.props;
    const { formAction, ...rest } = values;
    if (formAction === "clientNext") {
      next(rest);
    }
  };

  handleValidate = values => {
    const errors = {};
    
    if (this.props.clientID) {
      const firstName = get(values, "firstName");
      if (P.isEmpty(firstName)) {
        set(errors, "firstName", S.FORM_FIELD_REQUIRED);
      }
      const clientEmail = get(values, "email");
      if (P.isEmpty(clientEmail)) {
        set(errors, "email", S.FORM_FIELD_REQUIRED);
      }
    }
    
    const dateOfBirth = get(values, "dateOfBirth");
    if (P.isEmpty(dateOfBirth)) {
      set(errors, "dateOfBirth", S.FORM_FIELD_REQUIRED);
    }

    const genderTypeID = get(values, "genderTypeID");
    if (isEmptyOrDefault(genderTypeID)) {
      set(errors, "genderTypeID", S.FORM_FIELD_REQUIRED);
    }

    const annualIncome = get(values, "annualIncome");
    if (isEmptyOrDefault(annualIncome)) {
      set(errors, "annualIncome", S.FORM_FIELD_REQUIRED);
    }

    const stateID = get(values, "stateID");
    if (isEmptyOrDefault(stateID)) {
      set(errors, "stateID", S.FORM_FIELD_REQUIRED);
    }

    const occupationTypeID = get(values, "occupationTypeID");
    if (isEmptyOrDefault(occupationTypeID)) {
      set(errors, "occupationTypeID", S.FORM_FIELD_REQUIRED);
    }

    const isGovernment = get(values, "isGovernment");
    if (isGovernment) {
      const govtYears = get(values, "govtYears");
      if (!govtYears) {
        set(errors, "govtYears", S.FORM_FIELD_REQUIRED);
      }
    }

    const isBusinessOwner = get(values, "isBusinessOwner");
    if (isBusinessOwner) {
      const yearsInBusiness = get(values, "yearsInBusiness");
      if (!yearsInBusiness) {
        set(errors, "yearsInBusiness", S.FORM_FIELD_REQUIRED);
      }

      const employees = get(values, "employees");
      if (!employees) {
        set(errors, "employees", S.FORM_FIELD_REQUIRED);
      }
    }

    const phoneNumber = get(values, "phone")
    const pattern = P.PHONE_NUMBER_REGEX
    if (phoneNumber && !pattern.test(phoneNumber)) {
      set(errors, "phone", S.FORM_FIELD_INVALID)
    }

    const doesWorkFromHome = get(values, "doesWorkFromHome");
    if (doesWorkFromHome) {
      const percentWorkFromHome = get(values, "percentWorkFromHome");
      if (!percentWorkFromHome) {
        set(errors, "percentWorkFromHome", S.FORM_FIELD_REQUIRED);
      }
    }

    return errors;
  };

  render() {
    const officePhoneMask = { name: 'phone-1', parse: '(999) 999-9999.999999' }
    const phoneMask = { name: 'phone-1', parse: '(999) 999-9999' }

    const {
      clientOptions,
      selectedOcc,
      proposal,
      isSubmitting,
      isSavingNext,
    } = this.props;

    const prefixOptions = addDefaultOption(S.PDC_PREFIX_LABEL, clientOptions.selectPrefixList);
    const credentialOptions = addDefaultOption(
      S.PDC_CREDENTIALS_LABEL,
      clientOptions.selectCredentialTypeList
    );
    const stateOptions = addDefaultOption(S.PDC_STATE_LABEL, clientOptions.selectStateList);
    const genderOptions = addDefaultOption(S.PDC_GENDER_LABEL, clientOptions.selectGenderList);
    const tobaccoTypeOptions = addDefaultOption(
      S.PDC_TOBACCO_TYPE_LABEL,
      clientOptions.selectTobaccoTypeList
    );
    const tobaccoFrequencyOptions = addDefaultOption(
      S.PDC_TOBACCO_FREQUENCY_LABEL,
      clientOptions.selectTobaccoFrequencyList
    );
    const governmentTypeOptions = addDefaultOption(
      S.PDC_GOVERNMENT_TYPE_LABEL,
      clientOptions.selectGovernmentTypeList
    );

    const booleans = [{ key: S.PDC_YES, value: 1 }, { key: S.PDC_NO, value: 0 }];
    const booleanOptions = addDefaultOption("", booleans);

    const selectPaidByTypeList = addDefaultOption(
      S.PDC_PAID_BY_LABEL,
      get(clientOptions, "selectPaidByTypeList", [])
    );

    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        validate={this.handleValidate}
        initialValues={proposal.client}
        mutators={{ ...arrayMutators }}>
        {formProps => {
          const formValues = formProps.values;

          const isGovernment = get(formValues, "isGovernment");
          const isBusinessOwner = get(formValues, "isBusinessOwner");
          const doesWorkFromHome = get(formValues, "doesWorkFromHome");

          const doesUseTobacco = get(formValues, "doesUseTobacco");
          const hasMedicalNotes = get(formValues, "hasMedicalNotes");
          const hasSpouse = get(formValues, "hasSpouse");
          const hasExistingCoverage = get(formValues, "hasExistingCoverage");

          const selectedOccOption = [];
          if (selectedOcc) {
            selectedOccOption.push(keyValueToDropdownOption(selectedOcc));
          }

          return (
            <form
              className="client__form-wrap"
              onSubmit={formProps.handleSubmit}
              id={"proposal-builder-form-1"}>              
              <Container>
                <Row>
                  <Col>
                    <h1 className="heading3">{S.PDC_CLIENT_INFORMATION_TITLE}</h1>
                  </Col>
                </Row>
                <div className="row" key="client">
                  <div className="col">
                    <Container className="bootsrap-container-override">
                      <Row>
                        <Col sm="3">
                          <SingleSelectField
                            className="client__drop-input"
                            key={"prefix-field"}
                            name={`prefixTypeID`}
                            label={S.PDC_PREFIX_LABEL}
                            options={prefixOptions}
                            parse={(value, name) => {
                              return parseInt(value, 10);
                            }}
                          />
                        </Col>
                        <Col sm="3">
                          <SingleSelectField
                            className="client__drop-input"
                            key={"credential-field"}
                            name={`credentialTypeID`}
                            label={S.PDC_CREDENTIALS_LABEL}
                            options={credentialOptions}
                          />
                        </Col>
                        <Col sm="3">
                          <SingleSelectField
                            className="client__drop-input"
                            key={"state-field"}
                            name={`stateID`}
                            label={S.PDC_STATE_LABEL}
                            options={stateOptions}
                            isRequired={true}
                          />
                        </Col>
                      </Row>
                      {this.props.clientID 
                        ?<React.Fragment>
                          <Row>
                              <Col>
                                <TextField
                                  className="client__name-input"
                                  key={"client-first-name"}
                                  name={`firstName`}
                                  label={S.PDC_CLIENT_FIRST_NAME_LABEL}
                                />
                              </Col>
                              <Col>
                                <TextField
                                  className="client__name-input"
                                  key={"client-last-name"}
                                  name={`lastName`}
                                  label={S.PDC_CLIENT_LAST_NAME_LABEL}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="emailNoteCustom">
                                <TextField
                                  className="client__email-input"
                                  key={"client-email-name"}
                                  name={`email`}
                                  label={S.PDC_CLIENT_EMAIL_NAME_LABEL}
                                />
                                <label className="clientNote">{S.PDC_CLIENT_EMAIL_NAME_NOTE}</label>
                              </Col>
                              <Col className="emailNoteCustom">
                                <TextField
                                  className="client__phone-input"
                                  key={"client-phone"}
                                  name={`phone`}
                                  label={S.PDC_CLIENT_PHONE}
                                  type='tel'
                                  parse={formatString(officePhoneMask.parse)}
                                />
                                <label className="clientNote">{S.PDC_CLIENT_PHONE_NAME_NOTE}</label>
                              </Col>
                            </Row>
                          </React.Fragment> 
                        : <Row>
                            <Col>
                              <TextField
                                className="client__name-input"
                                key={"client-first-name"}
                                name={`firstName`}
                                label={S.PDC_CLIENT_FIRST_NAME_LABEL}
                              />
                            </Col>
                            <Col>
                              <TextField
                                className="client__name-input"
                                key={"client-last-name"}
                                name={`lastName`}
                                label={S.PDC_CLIENT_LAST_NAME_LABEL}
                              />
                            </Col>
                          </Row>
                      }

                      <Row>
                        <Col sm="3">
                          <SingleSelectField
                            className="client__drop-input"
                            key={"gender-select"}
                            name={`genderTypeID`}
                            isRequired={true}
                            label={S.PDC_GENDER_LABEL}
                            options={genderOptions}
                            parse={(value, name) => {
                              return parseInt(value, 10);
                            }}
                          />
                        </Col>
                        <Col sm="3">
                          <SimpleDatePickerField
                            key={"date-of-birth"}
                            name={`dateOfBirth`}
                            label={S.PDC_DOB_LABEL}
                          />
                          <OnBlur name={`dateOfBirth`}>
                            {() => {
                              const dob = get(formValues, "dateOfBirth");
                              const asDate = new Date(dob);
                              // if it's in the future, skip asking for age
                              if (dob && asDate < new Date()) {
                                this.props.fetchAge(dob);
                              }
                            }}
                          </OnBlur>
                        </Col>
                        <Col sm="6">
                          <Age />
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="3">
                          <TextField
                            key={"height-field"}
                            name={`height`}
                            label={S.PDC_HEIGHT_LABEL}
                          />
                        </Col>
                        <Col sm="3">
                          <TextField
                            key={"weight-field"}
                            name={`weight`}
                            label={S.PDC_WEIGHT_LABEL}
                          />
                        </Col>
                      </Row>

                      {/**
                        * OCCUPATION SECTION
                        */}
                      <Row>
                        <Col>
                          <h1 className="heading3">{S.PDC_OCCUPATION_TITLE}</h1>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='6' className="clientNoteCustom">
                          <AsyncTypeaheadField
                            className="client__drop-input"
                            key={"occupation-name"}
                            name={`occupationTypeID`}
                            label={S.PDC_OCCUPATION_NAME_LABEL}
                            fetch={this.searchOccupations}
                            isRequired={true}
                            selected={selectedOccOption}
                            placeholder="Type here..."
                            parse={options => {
                              if (options.length > 0) {
                                return options[0].value;
                              }
                            }}
                          />
                          <OnChange name={`occupationTypeID`}>
                            {value => {
                              const occupationTypeID = parseInt(value, 10);
                              if (occupationTypeID) {
                                this.props.fetchOccupation(occupationTypeID);
                              }
                            }}
                          </OnChange>
                          <label className="clientNote">{S.PDC_CLIENT_OCCUPATION_NOTE}</label>
                        </Col>
                        <Col sm='2'>
                          <TextField
                            key={"occupationOther-field"}
                            name={`occupationOther`}
                            label={S.PDC_OCCUPATION_OTHER_NAME_LABEL}
                            options={[]}
                          />
                        </Col>
                        <Col sm='4'>
                          <TextField
                            key={"duties-field"}
                            name={`duties`}
                            label={S.PDC_DUTIES_LABEL}
                            options={[]}
                          />
                        </Col>
                      </Row>
                      <Row className="incomeRow">
                        <Col sm="3">
                          <NumberFormatField
                            key={"annual-income-field"}
                            name={`annualIncome`}
                            label={S.PDC_ANNUAL_INCOME_LABEL}
                            thousandSeparator={true}
                            prefix={"$"}
                            isRequired={true}
                            decimalScale={2}
                            format={value => {
                              return parseFloat(String(value)).toFixed(2);
                            }}
                          />
                        </Col>
                        <Col sm="3">
                          <NumberFormatField
                            key={"bonus-income-field"}
                            name={`bonus`}
                            label={S.PDC_BONES_LABEL}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            format={value => {
                              return parseFloat(String(value)).toFixed(2);
                            }}
                          />
                        </Col>
                        <Col sm="3">
                          <NumberFormatField
                            key={"passive-income-field"}
                            name={`passiveIncome`}
                            label={S.PDC_PASSIVE_INCOME_LABEL}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            format={value => {
                              return parseFloat(String(value)).toFixed(2);
                            }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <div className="client__checkbox-group">
                            <Container fluid className="bootsrap-container-override">
                              <Row>
                                <Col sm="3">
                                  <CheckboxField
                                    key={"government-button"}
                                    name={`isGovernment`}
                                    label={S.PDC_GOVERNMENT_LABEL}
                                  />
                                </Col>
                                <Col sm="3">
                                  <CheckboxField
                                    key={"business-owner-button"}
                                    name={`isBusinessOwner`}
                                    label={S.PDC_BUSINESS_OWNER_LABEL}
                                  />
                                </Col>
                                <Col sm="3">
                                  <CheckboxField
                                    key={"work-from-home-button"}
                                    name={`doesWorkFromHome`}
                                    label={S.PDC_WORK_FROM_HOME_LABEL}
                                  />
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        {isGovernment && (
                          <Col sm="3">
                            <TextField
                              key="government-years-field"
                              name={`govtYears`}
                              type={"number"}
                              label={S.PDC_GOVERNMENT_YEARS_LABEL}
                            />
                          </Col>
                        )}

                        {isGovernment && (
                          <Col sm="3">
                            <SingleSelectField
                              key="government-type-field"
                              name={`governmentTypeID`}
                              label={S.PDC_GOVERNMENT_TYPE_LABEL}
                              options={governmentTypeOptions}
                            />
                          </Col>
                        )}

                        {isBusinessOwner && (
                          <Col sm="3">
                            <TextField
                              key="years-in-business-field"
                              name={`yearsInBusiness`}
                              type={"number"}
                              label={S.PDC_YEARS_IN_BUSINESS_LABEL}
                            />
                          </Col>
                        )}

                        {isBusinessOwner && (
                          <Col sm="3">
                            <TextField
                              key="employees-field"
                              name={`employees`}
                              type={"number"}
                              label={S.PDC_EMPLOYEES_LABEL}
                            />
                          </Col>
                        )}

                        {doesWorkFromHome && (
                          <Col sm="3">
                            <NumberFormatField
                              key="percent-work-from-home-field"
                              name={`percentWorkFromHome`}
                              label={S.PDC_PERCENTAGE_FROM_HOME}
                              isAllowed={P.isValidPercentage}
                              decimalScale={0}
                            />
                          </Col>
                        )}
                      </Row>

                      {/**
                        * TOBACCO USAGE
                        */}

                      <Row>
                        <Col>
                          <ToggleField
                            key={"tobacco-usage-header"}
                            name={`doesUseTobacco`}
                            label={S.PDC_TOBACCO_USAGE_TITLE}
                          />
                        </Col>
                      </Row>
                      {doesUseTobacco && (
                        <Row>
                          <Col sm="3">
                            <SingleSelectField
                              className="client__drop-input"
                              key={"tobacco-type-select"}
                              name={`tobaccoTypeID`}
                              label={S.PDC_TOBACCO_TYPE_LABEL}
                              options={tobaccoTypeOptions}
                              parse={(value, name) => {
                                return parseInt(value, 10);
                              }}
                            />
                          </Col>
                          <Col sm="3">
                            <SingleSelectField
                              className="client__drop-input"
                              key={"tobacco-frequency-select"}
                              name={`tobaccoFrequencyTypeID`}
                              label={S.PDC_TOBACCO_FREQUENCY_LABEL}
                              options={tobaccoFrequencyOptions}
                              parse={(value, name) => {
                                return parseInt(value, 10);
                              }}
                            />
                          </Col>
                          <Col sm="3">
                            <SimpleDatePickerField
                              key={"tobacco-last-used-field"}
                              name={`tobaccoLastUsedDate`}
                              label={S.PDC_TOBACCO_LAST_USE_LABEL}
                            />
                          </Col>
                        </Row>
                      )}
                      {/**
                        * MEDICAL NOTES
                        */}
                      <Row>
                        <Col>
                          <ToggleField
                            key={"medical-notes-header"}
                            name={`hasMedicalNotes`}
                            label={S.PDC_MEDICAL_NOTES_TITLE}
                          />
                        </Col>
                      </Row>
                      {hasMedicalNotes && (
                        <Row>
                          <Col>
                            <MedicalNotes
                              name={`conditions`}
                              formValues={formValues}
                            />
                          </Col>
                        </Row>
                      )}

                      {/**
                        * SPOUSE DETAILS
                        */}
                      <Row>
                        <Col>
                          <ToggleField
                            key={"spouse-details-header"}
                            name={`hasSpouse`}
                            label={S.PDC_SPOUSE_DETAILS_TITLE}
                          />
                        </Col>
                      </Row>
                      {hasSpouse && (
                        <Row>
                          <Col>
                            <TextField
                              key={"spouse-details-field"}
                              name={`spouseDetails`}
                              label={S.PDC_SPOUSE_DETAILS_LABEL}
                            />
                          </Col>
                        </Row>
                      )}
                      {/**
                        * Existing Coverage
                        */}
                      <Row>
                        <Col>
                          <ToggleField
                            key="existing-field-header"
                            name={`hasExistingCoverage`}
                            label={S.PDC_EXISTING_COVERAGE}
                          />
                        </Col>
                      </Row>

                      {hasExistingCoverage && (
                        <Row>
                          <Col sm="6">
                            <NumberFormatField
                              key="is-individual-field"
                              name={`individualCoverage`}
                              label={S.PDC_INDIVIDUAL_LABEL}
                              thousandSeparator={true}
                              prefix={"$"}
                              decimalScale={2}
                              format={value => {
                                return value
                                  ? parseFloat(String(value)).toFixed(2)
                                  : 0;
                              }}
                            />
                          </Col>
                          <Col sm="6">
                            <SingleSelectField
                              key="replace-existing-field"
                              name={`isReplaceExistingCoverage`}
                              label={S.PDC_REPLACE_EXISTING_LABEL}
                              options={booleanOptions}
                            />
                          </Col>
                        </Row>
                      )}
                      {hasExistingCoverage && (
                        <Row>
                          <Col>
                            <NumberFormatField
                              key={"group-percentage-field"}
                              name={`groupPercentage`}
                              label={S.PDC_GROUP_PERCENT_LABEL}
                              isAllowed={P.isValidPercentage}
                              format={value => {
                                return parseFloat(String(value));
                              }}
                            />
                          </Col>
                          <Col>
                            <NumberFormatField
                              key={"group-cap-field"}
                              name={`groupCap`}
                              label={S.PDC_GROUP_CAP_LABEL}
                              thousandSeparator={true}
                              prefix={"$"}
                              decimalScale={2}
                              format={value => {
                                return parseFloat(String(value)).toFixed(2);
                              }}
                            />
                          </Col>
                          <Col>
                            <SingleSelectField
                              key="paid-by-field"
                              name={`paidByTypeID`}
                              label={S.PDC_PAID_BY_LABEL}
                              options={selectPaidByTypeList}
                            />
                          </Col>
                        </Row>
                      )}
                    </Container>
                  </div>
                </div>
                <Row>
                  <Col className="center">
                    <button
                      className="button__orange"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={e => {
                        formProps.form.change("formAction", "clientNext");
                      }}>
                      {isSavingNext ? <Spinner color="white" /> : S.PC_NEXT_BUTTON}
                    </button>
                  </Col>
                </Row>
              </Container>
              
            </form>
          );
        }}
      </FinalForm>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  clientOptions: state.clientOptions.clientOptions,
  selectedOcc: state.proposalOptions.selectedOccupationOption,
  isSubmitting: state.clientOptions.isSubmitting,
  isSavingNext: state.clientOptions.isSavingNext,
  isSavingPrevious: state.clientOptions.isSavingPrevious
});

const mapDispatchToProps = {
  fetchAge,
  fetchOccupation
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Client);
