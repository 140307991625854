import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import classNames from "classnames";

import "./CheckboxField.scss";

interface ICheckboxFieldProps<el extends HTMLInputElement> extends FieldProps<any, el> {
  label: string;
}

export default class CheckboxField<el extends HTMLInputElement> extends React.Component<
  ICheckboxFieldProps<el>
> {
  render = () => {
    const { label, ...fieldProps } = this.props;
    return (
      <Field {...fieldProps} type="checkbox">
        {({ input, meta }) => (
          <div className={classNames("checkbox-field", { active: input.checked })}>
            <label >
              <input {...input} type="checkbox"  />
              {label}
            </label>
          </div>
        )}
      </Field>
    );
  };
}
