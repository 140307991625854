import React from 'react'
import { Form as FinalForm, Field, FormSpy } from 'react-final-form'
import { FORM_ERROR, FormApi } from 'final-form'
import { connect } from 'react-redux'
import { get, set } from 'lodash'
import { Container, Row, Col, Spinner } from 'reactstrap'
import createDecorator from 'final-form-calculate'

import { IRootState } from '../../store/reducers'
import { createBroker, fetchBroker, updateBroker } from '../../store/reducers/brokers'
import { fetchBrokerOptions, fetchCompanyAffiliate, fetchBrokerByPrimaryEmail } from '../../store/reducers/brokerBuilder';
import { fetchProductBuilderOptions } from "../../store/reducers/productBuilder";
import { IBroker, defaultBroker } from '../../store/models/broker.model'

import * as S from '../../constants/StringConstants'
import * as P from '../../util/utils.validators'
import TextField from '../../components/Form/TextField/TextField'
import SingleSelectField from '../../components/Form/SingleSelectField/SingleSelectField'
import TypeaheadField, {
  keyValueToDropdownOption,
  ITypeaheadOption
} from '../../components/Form/TypeaheadField/TypeaheadField'
import { RouteComponentProps } from 'react-router'
import './BrokerBuilder.scss'
import formatString from 'format-string-by-pattern'
import {
  addDefaultOption,
  undefinedIfDefault
} from '../../util/utils.defaultValues'
import { OnChange } from "react-final-form-listeners";

import ExistentBrokerNav from './ExistentBrokerNav';
import Header from '../Layout/Header'
import PreFooter from '../Layout/PreFooter'
import Footer from '../Layout/Footer'

export interface IBrokerBuilderState {
}

interface IBrokerBuilderProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> 
{
}

class BrokerBuilder extends React.Component<IBrokerBuilderProps, IBrokerBuilderState> {
  
  campaignId: number;
  companyId: number;

  constructor(props) {
    super(props);

    this.state = {
      emailDisabled: 'disabled'
    };
  }

  validate = (values: any) => {
    const errors = {}

    if (P.isEmpty(values.firstName)) {
      errors['firstName'] = S.FORM_FIELD_REQUIRED
    }

    if (P.isEmpty(values.lastName)) {
      errors['lastName'] = S.FORM_FIELD_REQUIRED
    }

    if (!values.primaryEmail) {
      errors['primaryEmail'] = S.FORM_FIELD_REQUIRED
    }

    const phoneFields = ['officePhone', 'cellPhone', 'faxPhone']
    phoneFields.map(fieldName => {
      const phoneNumber = get(values, fieldName)
      const pattern = P.PHONE_NUMBER_REGEX
      if (phoneNumber && !pattern.test(phoneNumber)) {
        set(errors, fieldName, S.FORM_FIELD_INVALID)
      }
    })

    const zipCode = get(values, 'address.zip')
    const zipPattern = P.ZIP_CODE_REGEX
    if (zipCode && !zipPattern.test(zipCode)) {
      set(errors, 'address.zip', S.FORM_FIELD_INVALID)
    }

    return errors
  }

  handleSubmit = async (values: any) => {
    const broker = this.formValuesToBroker(values);
    this.fillBrokerEmptyValues(broker);  
    
    try {
      var result = await this.props.createBroker(broker);
      const brokerID = +get(result, 'value.data')
      
      let brokerUrl = "/broker/" + brokerID;
      if (this.campaignId){
        brokerUrl += "/campaign/" + this.campaignId;
      }

      this.props.history.push(brokerUrl);
    } catch (err) {
      return { [FORM_ERROR]: 'Error Submitting' }
    }
  }

  fillBrokerEmptyValues(broker) {
    if (typeof get(broker, 'companyID') !== 'number') {
      set(broker, 'companyID', 0)
    }    
    if (typeof get(broker, 'affiliateID') !== 'number') {
      set(broker, 'affiliateID', undefined)
    }    
  }

  componentDidMount() {
    this.props.fetchProductBuilderOptions();
    

    const campaignParam = get(this.props.match.params, "campaignId");
    const companyParam = get(this.props.match.params, "companyId");
    var companyId = undefined;
    if(companyParam)
      companyId = +companyParam.match(/(\d+)/)[0];
    var campaignId = undefined;
    if(campaignParam)
      campaignId = +campaignParam.match(/(\d+)/)[0];
    
    this.campaignId = campaignId;
    this.companyId = companyId;

    this.props.fetchBrokerOptions(campaignId);
  }

  formValuesToBroker = (values: any) => {
    const primaryPhone = get(values, 'primaryPhone')
    const cellPhone = get(values, 'cellPhone')

    const brokerPhones = [
      {
        phoneNumber: get(values, 'officePhone'),
        isPrimary: primaryPhone === S.BRO_PHONE_TYPE_OFFICE,
        phoneType: S.BRO_PHONE_TYPE_OFFICE
      },
      {
        phoneNumber: cellPhone,
        isPrimary: primaryPhone === S.BRO_PHONE_TYPE_CELL,
        phoneType: S.BRO_PHONE_TYPE_CELL
      },
      {
        phoneNumber: get(values, 'faxPhone'),
        phoneType: S.BRO_PHONE_TYPE_FAX
      }
    ].filter(x => x && x.phoneNumber)

    const brokerAdditionalEmails = [
      {
        emailAddress: get(values, 'primaryEmail'),
        emailType: S.BRO_EMAIL_TYPE_PRIMARY
      },
      {
        emailAddress: get(values, 'alternateEmail'),
        emailType: S.BRO_EMAIL_TYPE_ALT
      },
      {
        emailAddress: get(values, 'ccEmail'),
        emailType: S.BRO_EMAIL_TYPE_CC
      },
      {
        emailAddress: get(values, 'requestorEmail'),
        emailType: S.BRO_EMAIL_TYPE_REQUESTER
      }
    ].filter(x => x && x.emailAddress)

    let affiliateIDs = get(values, 'affiliateID')
    let affiliateID

    if (affiliateIDs && affiliateIDs.length && affiliateIDs[0].value) {
      affiliateID = affiliateIDs[0].value
    } else if (!isNaN(affiliateIDs)) {
      affiliateID = affiliateIDs;
    } else {
      affiliateID = undefined;
    }

    let companyIDs = undefinedIfDefault(get(values, 'companyID'))
    //  Default behavior for an empty new form is to have it as undefined
    let companyID = undefined;

    if (Array.isArray(companyIDs) && companyIDs.length > 0) {
      // form was pre-populated with a list of options
      companyID = companyIDs[0].value
    } else if (Number.isInteger(companyIDs)) {
      // a single item has been selected
      companyID = companyIDs;
    }

    if (values.address) {
      values.address.stateID = undefinedIfDefault(values.address.stateID)
    }

    return {
      brokerID: get(values, 'brokerID'),
      firstName: get(values, 'firstName'),
      lastName: get(values, 'lastName'),
      preferredName: get(values, 'preferredName'),
      affiliateFreeTypeName: get(values, 'affiliateFreeTypeName'),
      companyFreeTypeName: get(values, 'companyFreeTypeName'),
      companyID: companyID,
      affiliateID: undefinedIfDefault(affiliateID),
      marketingCampaignID: undefinedIfDefault(get(values, 'marketingCampaignID')),
      HearAboutTypeID: undefinedIfDefault(get(values, 'hearAboutUsTypeID')),
      brokerAdditionalEmails,
      brokerPhones,
      address: get(values, 'address'),
      notes: get(values, 'notes')
    }
  }

  brokerToFormValues = (broker: IBroker, affiliates: ITypeaheadOption[]) => {
    const brokerPhones = get(broker, 'brokerPhones') || []
    const brokerAdditionalEmails = get(broker, 'brokerAdditionalEmails') || []
    const officePhoneItem = brokerPhones.find(x => x.phoneType === 1)

    const affiliate = affiliates.find(a => a.value === get(broker, 'affiliateID'))

    return {
      brokerID: get(broker, 'brokerID'),
      firstName: get(broker, 'firstName'),
      lastName: get(broker, 'lastName'),
      preferredName: get(broker, 'preferredName'),
      companyFreeTypeName: get(broker, 'companyFreeTypeName'),
      affiliateFreeTypeName: get(broker, 'affiliateFreeTypeName'),
      companyID: get(broker, 'companyID'),
      affiliateID: affiliate ? affiliate.value : undefined,
      marketingCampaignID: get(broker, 'marketingCampaignID'),
      hearAboutUsTypeID: get(broker, 'hearAboutUsTypeID'),
      address: get(broker, 'address'),
      notes: get(broker, 'notes'),
      officePhone: get(officePhoneItem, 'phoneNumber'),
      cellPhone: get(
        brokerPhones.find(x => x.phoneType === 2),
        'phoneNumber'
      ),
      faxPhone: get(
        brokerPhones.find(x => x.phoneType === 3),
        'phoneNumber'
      ),
      primaryPhone:
        officePhoneItem && officePhoneItem.isPrimary
          ? S.BRO_PHONE_TYPE_OFFICE
          : S.BRO_PHONE_TYPE_CELL,
      primaryEmail: get(
        brokerAdditionalEmails.find(x => x.emailType === 1),
        'emailAddress'
      ),
      alternateEmail: get(
        brokerAdditionalEmails.find(x => x.emailType === 2),
        'emailAddress'
      ),
      ccEmail: get(
        brokerAdditionalEmails.find(x => x.emailType === 3),
        'emailAddress'
      ),
      requestorEmail: get(
        brokerAdditionalEmails.find(x => x.emailType === 4),
        'emailAddress'
      )
    }
  }

  getPrimaryPhoneLabel(form: FormApi, key: string) {
    const field = form.getFieldState('primaryPhone')
    if (!field) {
      return ''
    }

    return field.value === key ? S.BRO_PRIMARY_LABEL : ''
  }

  get broker() {
    return this.props.brokers.map[this.props.match.params.id]
  }

  get brokerID() {
    const id = parseInt(this.props.match.params.id, 10)

    return id && !isNaN(id) ? id : null
  }

  formStateTemp = undefined;

  formStateHandler = createDecorator({
    field: /./,
    updates: (value, name, allValues, previousValues) => {
      this.formStateTemp = allValues;
      return {}
    }
  })

  render() {
    var brokerBuilderScope = this;

    const { existentBrokerIdByEmail } = this.props;
    const { dropdownOptions, companyAffiliate } = this.props
    const officePhoneMask = { name: 'phone-1', parse: '(999) 999-9999.999999' }
    const phoneMask = { name: 'phone-1', parse: '(999) 999-9999' }

    // if there's an id then we should be editing
    const broker = this.broker;

    const defaultValues = {
      affiliateID: undefined,
      companyID: undefined,
      howDidYouHearAboutUsID: undefined,
      marketingCampaignID: undefined,
      primaryPhone: S.BRO_PHONE_TYPE_OFFICE,
      ...this.formStateTemp
    }

    const affiliateList = (get(dropdownOptions, 'affiliateList') || []).map(
      keyValueToDropdownOption
    )

    // const companyList = (get(dropdownOptions, 'companyList') || []).map(
    //   keyValueToDropdownOption
    // )
    
    var companyFullList = get(dropdownOptions, 'companyList', []);
    var hearAboutUsFullList = get(dropdownOptions, 'hearAboutUsList', []);

    let affiliate = undefined;
    let selectedCompanyDetails = undefined;
    const affiliateParam = get(this.props.match.params, "affiliateId");
    const campaignParam = get(this.props.match.params, "campaignId");
    const companyParam = get(this.props.match.params, "companyId");

    var affiliateId = undefined;
    if(affiliateParam)
      affiliateId = +affiliateParam.match(/(\d+)/)[0];
    
    var companyId = undefined;
    if(companyParam)
      companyId = +companyParam.match(/(\d+)/)[0];

    var campaignId = undefined;
    if(campaignParam)
      campaignId = +campaignParam.match(/(\d+)/)[0];

    let howDidYouHearAboutUs = undefined;

    let isCompanyRendering = false;
    let isAffiliateRendering = false;
    if (companyId) {
      isCompanyRendering = true;
      selectedCompanyDetails = companyFullList.find(a => a.value === companyId);
      if(selectedCompanyDetails) {
        affiliate = affiliateList.find(a => a.value === selectedCompanyDetails.affiliateId);
        defaultValues.affiliateID = selectedCompanyDetails.affiliateId;
      }
    }
    else if (affiliateId) {
      isAffiliateRendering = true;
      affiliate = affiliateList.find(a => a.value === affiliateId);
      companyFullList = companyFullList.filter(x => x.affiliateId === affiliateId);
    }
    
    if (campaignId) {
      howDidYouHearAboutUs = hearAboutUsFullList.find(a => a.value === campaignId); 
    }
    
    if (!howDidYouHearAboutUs && isAffiliateRendering) {
      howDidYouHearAboutUs = hearAboutUsFullList.find(a => a.key === 'Affiliate');
    }
    else if (!howDidYouHearAboutUs) {
      howDidYouHearAboutUs = hearAboutUsFullList.find(a => a.key === 'Other');
    }
    
    const stateList = addDefaultOption(
      S.BRO_STATE_LABEL,
      get(dropdownOptions, 'selectStateList', [])
    )
    
    // If viewing an existing broker with an affiliate always select that affiliate.
    // If there is no existing broker affiliate use the affiliate of the selected company.
    const brokerAffiliateID = get(broker, 'affiliateID');
    if (brokerAffiliateID) {
      affiliate = affiliateList.find(a => a.value === brokerAffiliateID);
      defaultValues.affiliateID = affiliate ? affiliate.value : 0;
    } else if (companyAffiliate) {
      affiliate = affiliateList.find(a => a.value === get(companyAffiliate, 'affiliateID'));
      defaultValues.affiliateID = affiliate ? affiliate.value : 0
      set(broker, 'affiliateID', get(affiliate, 'value'));
    }
    // If affiliateId we received via params - override all existent logic
    if(affiliateId && affiliate){
      defaultValues.affiliateID = affiliate ? affiliate.value : 0;
    }
    // If howDidYouHearAboutUsId we received via params - override all existent logic
    if(howDidYouHearAboutUs){
      defaultValues.hearAboutUsTypeID = howDidYouHearAboutUs ? howDidYouHearAboutUs.value : 0;
    }

    const initialValues = broker ? this.brokerToFormValues(broker, affiliateList) : defaultValues;

    if (selectedCompanyDetails) {
      initialValues.companyID = selectedCompanyDetails.value;
    }
    
    
    return (
      <div>
        <Header />
        <FinalForm
          validate={this.validate}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          decorators={[this.formStateHandler]}
          render={({ handleSubmit, submitting, invalid, values }) => {

            var unfilteredCompanyList = get(dropdownOptions, 'companyList', []);
            if(affiliate ) {
              unfilteredCompanyList = unfilteredCompanyList.filter(c => c.affiliateId === affiliate.value);
            }
            
            const companyList = addDefaultOption(
              "",
              unfilteredCompanyList.sort(function (a, b){
                return a.key.localeCompare(b.key);
              })
            ).map(keyValueToDropdownOption)
            
            let selectedCompany = undefined;
            if (initialValues.companyID) {
              selectedCompany = companyList.find(c => c.value === initialValues.companyID);
            }
            
            return (
              <div className='broker-builder'>
                <h1 className='heading1 grey--light'>{S.BRO_NEW_TITLE}</h1>
                <form onSubmit={handleSubmit}>
                  <div className='broker-builder__form'>
                    <section className='broker-builder__wrap'>
                      <Container className='broker-builder__container'>
                        <Row>
                          <Col>
                            <h1 className='heading3'>{S.BRO_INFO_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='3'>
                            <TextField
                              name='firstName'
                              key='broker.firstName-key'
                              label={S.BRO_FIRST_NAME_LABEL}
                            />
                          </Col>
                          <Col sm='3'>
                            <TextField
                              name='lastName'
                              key='broker.lastName-key'
                              label={S.BRO_LAST_NAME_LABEL}
                            />
                          </Col>
                          <Col sm='6'>
                            <TextField
                              name='preferredName'
                              key='broker.preferredName-key'
                              label={S.BRO_PREF_NAME_LABEL}
                            />
                          </Col>
                        </Row>
                          {
                            isCompanyRendering
                            ?
                            <Row> 
                              <Col sm='6'>
                                <TypeaheadField
                                  className='overview__broker-details-input'
                                  name='affiliateID'
                                  key='broker.affiliateID-key'
                                  label={S.BRO_AFFILIATE_LABEL}
                                  options={affiliateList}
                                  selected={affiliate}
                                  disabled='disabled'
                                  placeholder="Type here..."
                                />
                              </Col>
                              <Col sm='6'>
                                <TypeaheadField
                                  className='overview__broker-details-input'
                                  name='companyID'
                                  key='broker.companyID-key'
                                  label={S.BRO_COMPANY_LABEL}
                                  options={affiliateList}
                                  selected={selectedCompany}
                                  disabled='disabled'
                                  placeholder="Type here..."
                                />
                              </Col>
                            </Row>
                            : ""
                          }
                          {isAffiliateRendering && !isCompanyRendering
                           ? <Row> 
                             <Col sm='6'>
                              <TypeaheadField
                                className='overview__broker-details-input'
                                name='affiliateID'
                                key='broker.affiliateID-key'
                                label={S.BRO_AFFILIATE_LABEL}
                                options={affiliateList}
                                selected={affiliate}
                                disabled='disabled'
                                placeholder="Type here..."
                              />
                            </Col>
                            <Col sm='6'>
                              <TypeaheadField
                                className='overview__broker-details-input'
                                name='companyID'
                                label={S.BRO_COMPANY_LABEL}
                                key='broker.companyID-key'
                                options={companyList}
                                selected={selectedCompany}
                                placeholder="Type here..."
                              />
                              <OnChange name='companyID'>
                                  {value => {
                                    const companyID = get(value, '0.value');
                                    if (companyID && !affiliateId) {
                                      try {
                                        this.props.fetchCompanyAffiliate(companyID);
                                        set(this.broker, 'affiliateID', 0);
                                        set(this.broker, 'companyID', companyID);

                                      } catch (e) {
                                        console.log('exception:', e)
                                      }
                                    }
                                  }}
                                </OnChange>
                            </Col>
                            </Row>
                           :"" }
                          {!isAffiliateRendering && !isCompanyRendering
                            ?<Row>
                              <Col sm='6' >
                                <TextField
                                  name='affiliateFreeTypeName'
                                  key='broker.affiliateFreeTypeName-key'
                                  label={S.BRO_AFFILIATE_LABEL}
                                  placeholder="Type here..."
                                />
                              </Col>
                              <Col sm='6' >
                                <TextField
                                  name='companyFreeTypeName'
                                  key='broker.companyFreeTypeName-key'
                                  label={S.BRO_COMPANY_LABEL}
                                  placeholder="Type here..."
                                />
                              </Col>
                              </Row>
                            :""
                          }
                        <Row>
                          <Col>
                            <h1 className='heading3'>{S.BRO_PHONE_TITLE}</h1>
                          </Col>
                        </Row>
                        <FormSpy
                          render={({ form }) => (
                            <Row>
                              <Col sm='6' className='broker-builder__phone-radio'>
                                <div className='broker-builder__short-div'>
                                  <TextField
                                    name='officePhone'
                                    key='broker.officePhone-key'
                                    label={S.BRO_PHONE_TYPE_OFFICE}
                                    type='tel'
                                    parse={formatString(officePhoneMask.parse)}
                                  />
                                </div>
                                <div className='broker-builder__radio_wrap'>
                                  <label>
                                    <Field
                                      type='radio'
                                      name='primaryPhone'
                                      value={S.BRO_PHONE_TYPE_OFFICE}
                                      component='input'
                                    />{' '}
                                    {this.getPrimaryPhoneLabel(form, S.BRO_PHONE_TYPE_OFFICE)}
                                  </label>
                                </div>
                              </Col>
                              <Col sm='3' className='broker-builder__phone-radio'>
                                <div className='broker-builder__short-div'>
                                  <TextField
                                    name='cellPhone'
                                    key='broker.cellPhone-key'
                                    label={S.BRO_PHONE_TYPE_CELL}
                                    type='tel'
                                    parse={formatString(phoneMask.parse)}
                                  />
                                </div>
                                <div className='broker-builder__radio_wrap'>
                                  <label>
                                    <Field
                                      type='radio'
                                      name='primaryPhone'
                                      value={S.BRO_PHONE_TYPE_CELL}
                                      component='input'
                                    />{' '}
                                    {this.getPrimaryPhoneLabel(form, S.BRO_PHONE_TYPE_CELL)}
                                  </label>
                                </div>
                              </Col>
                              <Col sm='3'>
                                <TextField
                                  name='faxPhone'
                                  key='broker.faxPhone-key'
                                  label={S.BRO_PHONE_TYPE_FAX}
                                  type='tel'
                                  parse={formatString(phoneMask.parse)}
                                />
                              </Col>
                            </Row>
                          )}
                        />
                        <Row>
                          <Col>
                            <h1 className='heading3'>{S.BRO_EMAIL_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='6'>
                            <TextField
                              name='primaryEmail'
                              key='broker.primaryEmail-key'
                              label={`${S.BRO_EMAIL_TYPE_PRIMARY} Email`}
                              type='email'
                            />
                            <OnChange name='primaryEmail'>
                              {value => {
                                var primaryEmail = value;
                                if (primaryEmail && primaryEmail.length > 4){
                                  var re = /\S+@\S+\.\S+/;
                                  var isValid = re.test(primaryEmail);
                                  if(isValid) {
                                    this.props.fetchBrokerByPrimaryEmail(primaryEmail);
                                  }
                                }
                              }}
                            </OnChange>
                          </Col>
                          <Col sm='6'>
                            <TextField
                              name='alternateEmail'
                              key='broker.alternateEmail-key'
                              label={`${S.BRO_EMAIL_TYPE_ALT} Email`}
                              type='email'
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='6'>
                            <ExistentBrokerNav />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='6'>
                            <TextField
                              name='ccEmail'
                              key='broker.ccEmail-key'
                              label={`${S.BRO_EMAIL_TYPE_CC} Email`}
                              type='email'
                            />
                          </Col>
                          <Col sm='6'>
                            <TextField
                              name='requestorEmail'
                              key='broker.requestorEmail-key'
                              label={`${S.BRO_EMAIL_TYPE_REQUESTER} Email`}
                              type='email'
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h1 className='heading3'>{S.BRO_ADDRESS_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='6'>
                            <TextField
                              name='address.streetAddress'
                              key='broker.streetAddress-key'
                              label={S.BRO_STREET_ADDRESS_LABEL}
                            />
                          </Col>
                          <Col sm='3'>
                            <TextField
                              name='address.city'
                              key='broker.city-key'
                              label={S.BRO_CITY_LABEL}
                            />
                          </Col>
                          <Col sm='3'>
                            <Container className='bootsrap-container-override'>
                              <Row>
                                <Col>
                                  <SingleSelectField
                                    name='address.stateID'
                                    key='broker.state-key'
                                    label={S.BRO_STATE_LABEL}
                                    options={stateList}
                                  />
                                </Col>
                                <Col>
                                  <TextField
                                    name='address.zip'
                                    key='broker.zip-key'
                                    label={S.BRO_ZIP_LABEL}
                                  />
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                        <Row>
                          {campaignId 
                           ? <Col sm='6'>
                              <SingleSelectField
                                key={"hear_about key"}
                                label={S.PDO_HOW_DID_LABEL}
                                name={"hearAboutUsTypeID"}
                                options={hearAboutUsFullList}
                                disabled='disabled'
                              />
                            </Col>
                           : <Col sm='6'>
                              <SingleSelectField
                                  key={"hear_about key"}
                                  label={S.PDO_HOW_DID_LABEL}
                                  name={"hearAboutUsTypeID"}
                                  options={hearAboutUsFullList}
                                />
                            </Col>}
                        </Row>
                        <Row>
                          <Col>
                            <h1 className='heading3'>{S.BRO_NOTES_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <TextField
                              name='notes'
                              key='broker.notes-key'
                              label={S.BRO_NOTES_LABEL}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className='broker-builder__top-bar'>
                              <button
                                className='button5 button__orange'
                                type='submit'
                                disabled={submitting || existentBrokerIdByEmail}>
                                {submitting || existentBrokerIdByEmail ? <Spinner color="white" /> : S.BRO_SUBMIT_BUTTON}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>
                  </div>
                </form>
              </div>
            )
          }}
        />
        {/* <PreFooter /> */}
        <Footer /> 
      </div>
    )
  }
}

const mapStateToProps = ({
  brokers,
  brokerBuilder: { loading, companyAffiliate, existentBrokerIdByEmail, isAfiliateRequestInProgress, ...dropdownOptions }
}: IRootState) => ({
  brokers,
  companyAffiliate,
  isAfiliateRequestInProgress,
  dropdownOptions,
  existentBrokerIdByEmail
})

const mapDispatchToProps = {
  createBroker,
  fetchBrokerOptions,
  fetchBroker,
  updateBroker,
  fetchCompanyAffiliate,
  fetchBrokerByPrimaryEmail,
  fetchProductBuilderOptions
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrokerBuilder)
