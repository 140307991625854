import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Link, RouteComponentProps } from "react-router-dom";
import { Container, Row, Col, Spinner } from "reactstrap";
import { get } from "lodash";

import { IRootState } from "../../store/reducers";
import * as S from "../../constants/StringConstants";
import "./Quote.scss";
import Header from '../Layout/Header';
import FooterShort from '../Layout/FooterShort';
import HeaderHome from '../Layout/HeaderHome';

export interface IQuoteEngineProps extends StateProps, DispatchProps, RouteComponentProps {

}

export class QuoteEngine extends React.Component<IQuoteEngineProps> {
  render() {
    const affiliateParam = get(this.props.match.params, "affiliateId");
    const campaignParam = get(this.props.match.params, "campaignId");
    const companyParam = get(this.props.match.params, "companyId");

    var affiliateId = undefined;
    if(affiliateParam)
      affiliateId = +affiliateParam.match(/(\d+)/)[0];
    
    var companyId = undefined;
    if(companyParam)
      companyId = +companyParam.match(/(\d+)/)[0];

    var campaignId = undefined;
    if(campaignParam)
      campaignId = +campaignParam.match(/(\d+)/)[0];
    
    let brokerUrl = "/brokers";
    let loginUrl = "/login";
    if (companyId) {
      brokerUrl = brokerUrl + "/company/" + companyId + "/new";
    }
    else if (affiliateId){
      brokerUrl = brokerUrl + "/affiliate/" + affiliateId + "/new";
    } else {
      brokerUrl += "/new";
    }

    if (campaignId) {
      let campaignParam = "/campaign/" + campaignId;
      brokerUrl += campaignParam;
      loginUrl += campaignParam;
    }

    return (
      <div className="quoteWapper">
        <HeaderHome {...this.props} />
      
        <section className="quote">
          <Container fluid>
            <Row>
              <Col>
                  <div className="center">
                    <h1 className="red-text">{S.QUOTE_ENGINE}</h1>
                  </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="center">
                  <Link className="buttonred" to={brokerUrl}>{S.QUOTE_ENGINE_REGISTER_LABEL}</Link>
                </div>
              </Col>
            </Row>
      
            <Row>
              <Col>
                <div className="center">
                  <Link className="button" to={loginUrl}>{S.QUOTE_ENGINE_LOGIN_LABEL}</Link>
                </div>
              </Col>
            </Row>
            </Container>
        </section>
      
        <FooterShort />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }: IRootState) => ({
  isAuthenticated: auth.isAuthenticated
});
const mapDispatchToProps = { };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteEngine);
